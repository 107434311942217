<template>
  <main>
    <content-page-header>
      <common-page-title title="解約申請" />
      <div class="page-header-explanation">
        <p class="page-header-explanation-text">
          ジッセン!DXをご利用頂きまして誠にありがとうございます。<br />
          会員を退会すると、全ての講座の視聴ができなくなり作成したメモやお気に入りの情報などが消滅しますが、本当に退会してよろしいでしょうか？
        </p>
      </div>
    </content-page-header>
    <div class="content-body">
      <panel-body>
        <license-infomation license-type="premium" />
        <section class="cancel-content border-t border-t--decoration">
          <common-block-title
            v-if="!state.verification"
            title="サブスクリプションの停止"
            sub-title=""
          />
          <common-block-title v-else title="解約申請内容確認" sub-title="" />
          <form id="cancelForm" class="cancel-form">
            <label class="form-function">
              <div class="form-input-label pt-0">
                解約予定日<span class="form-input-label--explanation"
                  >本日解約申請された場合の 解約予定日付を表示しています</span
                >
              </div>
              <div class="w-full">
                <p v-if="nextPaymentOn !== ''">{{ oneDayAgo(nextPaymentOn) }}</p>
              </div>
            </label>

            <label class="form-function">
              <div
                class="form-input-label"
                :class="state.verification ? 'pt-0' : ''"
              >
                メールアドレス<sup class="type-required">*</sup>
              </div>
              <div class="w-full">
                <input
                  v-if="!state.verification"
                  type="email"
                  placeholder="メールアドレス"
                  v-model="state.email"
                />
                <p v-else>{{ state.email }}</p>
                <div
                  class="error-message"
                  v-for="(error, index) in state.errors.email"
                  :key="index"
                >
                  <icon name="error" class="icon" />{{ error }}
                </div>
              </div>
            </label>
            <label class="form-function">
              <div
                class="form-input-label"
                :class="state.verification ? 'pt-0' : ''"
              >
                パスワード<sup class="type-required">*</sup>
              </div>
              <div class="w-full with-icon">
                <template v-if="!state.verification">
                  <input
                    v-if="!state.verification"
                    :type="state.passwordCheck ? 'text' : 'password'"
                    placeholder="パスワード"
                    v-model="state.password"
                  />

                  <span
                    class="input-icon"
                    @click="state.passwordCheck = !state.passwordCheck"
                  >
                    <icon
                      v-if="!state.passwordCheck"
                      name="input-eye-icon"
                      class="icon"
                    />
                    <icon v-else name="input-eye-icon-active" class="icon" />
                  </span>
                </template>
                <p v-else>{{ passwordVerification(state.password) }}</p>
                <div
                  class="error-message"
                  v-for="(error, index) in state.errors.password"
                  :key="index"
                >
                  <icon name="error" class="icon" />{{ error }}
                </div>
              </div>
            </label>
            <div class="form-function items-start justify-start border-t">
              <div
                class="form-input-label"
                :class="state.verification ? 'pt-0' : ''"
              >
                解約理由<sup class="type-required">*</sup>
              </div>
              <div class="cancel-reason-list" v-if="!state.verification">
                <div
                  class="cancel-reason-list-item"
                  v-for="(item, i) in reasonContents"
                  :key="i"
                >
                  <input
                    type="checkbox"
                    v-model="state.reason"
                    class="form-input-check"
                    :value="i + 1"
                    :id="i"
                  />
                  <label :for="i">
                    {{ item }}
                  </label>
                </div>
              </div>
              <div class="cancel-reason-list" v-else>
                <div
                  class="cancel-reason-list-item"
                  v-for="(item, i) in state.reason"
                  :key="i"
                >
                  <p>{{ reasonContents[item - 1] }}</p>
                </div>
                <div
                  class="error-message"
                  v-for="(error, index) in state.errors.reason"
                  :key="index"
                >
                  <icon name="error" class="icon" />{{ error }}
                </div>
              </div>
            </div>
            <label class="form-function items-start">
              <div
                class="form-input-label"
                :class="state.verification ? 'pt-0' : ''"
              >
                期待すること<span class="form-input-label--explanation"
                  >今後のサービス向上の参考として、具体的な解約理由のご記載にご協力をお願いいたします。</span
                >
              </div>
              <div class="w-full">
                <textarea
                  v-if="!state.verification"
                  rows="4"
                  cols="40"
                  placeholder="期待することを記入"
                  v-model="state.service_request"
                ></textarea>
                <p v-else class="confirm-service-request">
                  {{ state.service_request }}
                </p>
                <div
                  class="error-message"
                  v-for="(error, index) in state.errors.service_request"
                  :key="index"
                >
                  <icon name="error" class="icon" />{{ error }}
                </div>
              </div>
            </label>
            <label class="form-function items-start">
              <div
                class="form-input-label"
                :class="state.verification ? 'pt-0' : ''"
              >
                使いやすかった機能
              </div>
              <div class="w-full">
                <textarea
                  v-if="!state.verification"
                  rows="4"
                  cols="40"
                  placeholder="使いやすかった機能を記入"
                  v-model="state.good_function"
                ></textarea>
                <p v-else class="confirm-good-function">
                  {{ state.good_function }}
                </p>
                <div
                  class="error-message"
                  v-for="(error, index) in state.errors.good_function"
                  :key="index"
                >
                  <icon name="error" class="icon" />{{ error }}
                </div>
              </div>
            </label>
          </form>
        </section>

        <div class="submit-area">
          <button
            class="submit-area-button submit-area-button--cancel"
            :disabled="state.isLoading"
            @click="cancel"
          >
            Back
          </button>
          <button
            v-if="!state.verification"
            class="submit-area-button submit-area-button--save"
            :disabled="
              state.email == '' ||
              state.password == '' ||
              state.reason == '' ||
              state.isLoading
            "
            @click="validateAction"
          >
            解約を申し込む</button
          ><button
            v-else
            class="submit-area-button submit-area-button--save"
            :disabled="state.isLoading"
            @click="withdrawal"
          >
            解約を申し込む
          </button>
        </div>
      </panel-body>
    </div>
  </main>
  <mydialog
    :isShowAlert="state.showAlert"
    title="Thank you!"
    subTitle="ご利用ありがとうございました"
    message="プレミアムプランを解約しました。ご利用ありがとうございました。フィードバックを元に改善していきますので、また使っていただけるのをお待ちしています。"
    messageColor="black"
    submit="Topページへ戻る"
    @submitClick="LinkTop"
    @closeDialog="LinkTop"
  />
</template>

<script>
import { defineComponent, reactive, ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import http from "@/utils/http";
import display from "@/utils/display";
import contentPageHeader from "@/components/contentPageHeader.vue";
import CommonPageTitle from "@/components/CommonTitle/CommonPageTitle.vue";
import CommonBlockTitle from "@/components/CommonTitle/CommonBlockTitle.vue";
import PanelBody from "@/components/CommonPanelBody/PanelBody.vue";
import licenseInfomation from "@/views/apply/components/licenseInfomation.vue";
import mydialog from "@/components/CommonDialog/CommonDialog.vue";
export default defineComponent({
  name: "resetPasswordSend",

  components: {
    contentPageHeader,
    CommonPageTitle,
    PanelBody,
    licenseInfomation,
    CommonBlockTitle,
    mydialog,
  },
  props: [],
  setup() {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      verification: false,
      email: "",
      password: "",
      passwordCheck: false,
      reason: [],
      service_request: "",
      good_function: "",
      showAlert: false,
      errors: "",
      isLoading: false,
    });
    const isLogin = computed(() => store.state.user.isLogin);
    const isEnterprise = computed(() => store.state.user.isEnterprise);

    window.scrollTo(0, 0);

    const nextPaymentOn = ref("");
    http
      .get("/api/customers/me")
      .then((res) => {
        if (res.data.end_at) {
          router.push("/");
          return;
        }
        nextPaymentOn.value = res.data.next_payment_on;
      })
      .catch((error) => {
        console.log("エラー:", error.response.data.errors);
      });
    const oneDayAgo = (nextPaymentOn) => {
      const date = new Date(display.toDate(nextPaymentOn));
      return display.timestampToYmd(date.setDate(date.getDate() - 1));
    };

    const cancel = () => {
      if (state.verification) {
        state.verification = false;
      }
      router.go(-1);
    };

    // バリデーション実装
    const validateAction = () => {
      state.isLoading = true;
      http
        .post("/api/contracts/cancel/validate", {
          email: state.email,
          password: state.password,
          reason: state.reason,
          service_request: state.service_request,
          good_function: state.good_function,
        })
        .then(() => {
          state.errors = [];
          window.history.pushState(null, null, "/cancel/input/confirm");
          document.getElementById("app").scrollIntoView();

          state.verification = true;
          state.isLoading = false;
        })
        .catch((error) => {
          document.getElementById("app").scrollIntoView();

          console.log("エラー:", error.response.data.errors);
          if (error.config && error.response && error.response.status === 400) {
            state.errors = error.response.data.errors;
          }

          state.isLoading = false;
        });
    };

    // 退会の申し込み
    const withdrawal = () => {
      state.isLoading = true;
      http
        .post("/api/contracts/cancel", {
          email: state.email,
          password: state.password,
          reason: state.reason,
          service_request: state.service_request,
          good_function: state.good_function,
        })
        .then(() => {
          state.showAlert = true;

          window.setTimeout(function () {
            state.showAlert = false;
            document.body.style.overflow = "";
            router.push("/");
          }, 5000);
        })
        .catch((error) => {
          console.log("エラー:", error.response.data.errors);
          if (error.config && error.response && error.response.status === 400) {
            state.errors = error.response.data.errors;
          }

          state.isLoading = false;
        });
    };
    const LinkTop = () => {
      document.body.style.overflow = "";
      location.href = "/";
    };

    const passwordVerification = (el) => {
      const secret = "●";
      return secret.repeat(el.length);
    };

    onMounted(async () => {
      if (!isLogin.value) {
        router.push("/");
        return;
      }
      if (isEnterprise.value) {
        router.push("/");
        return;
      }
    });
    watch(
      () => store.state.user.isLogin,
      async () => {
        if (!isLogin.value) {
          router.push("/");
          return;
        }
      }
    );
    watch(
      () => store.state.user.isEnterprise,
      async () => {
        if (isEnterprise.value) {
          router.push("/");
          return;
        }
      }
    );

    return {
      store,
      state,
      cancel,
      validateAction,
      nextPaymentOn,
      oneDayAgo,
      reasonContents: [
        "コース・講座の数が少なかった",
        "講師の質が期待ほどでなかった",
        "学びたいスキルを取得できたから",
        "講座の内容が期待ほどでなかった",
        "その他",
      ],
      withdrawal,
      LinkTop,
      passwordVerification,
    };
  },
});
</script>

<style lang="scss" scoped>
::v-deep(.content-header) {
  height: auto;
  padding: 100px 100px 200px;
  background-size: cover;
  @include mq(sm) {
    padding: 100px 20px 200px;
  }
}
.content-body {
  position: relative;
  margin-top: -100px;
  margin-right: 50px;
  margin-left: 50px;
  margin-bottom: 50px;
  z-index: 1;

  @include mq(sm) {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.cancel-content {
  position: relative;
  margin-top: 65px;
  padding-top: 65px;
}

.cancel-form {
  padding: 65px 0;
  border-bottom: 0.5px solid #0f0f11;
}
.cancel-reason-list-item {
  &:nth-of-type(n + 2) {
    margin-top: 1em;
  }
}
.confirm-service-request,
.confirm-good-function {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>